import React from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import ProjectImages from '../../components/ProjectImages';

import projectsBanner from './../../assets/projects/projectsBanner.png';

import imgProject1 from './../../assets/projects/1_jakartaInternationalStadium.png';
import imgProject2 from './../../assets/projects/2_tamanIsmailMarzuki.png';
import imgProject3 from './../../assets/projects/3_bandaraSamratManado.png';
import imgProject4 from './../../assets/projects/4_apartemenTransparkBintaro.png';
import imgProject5 from './../../assets/projects/5_apartmenEmbarcadero.png';
import imgProject6 from './../../assets/projects/6_pakubowoMenteng.png';
import imgProject7 from './../../assets/projects/7_transparkCibubur.png';
import imgProject8 from './../../assets/projects/8_bintaroExchange.png';
import imgProject9 from './../../assets/projects/9_apartmenSakuraGarden.png';
import imgProject10 from './../../assets/projects/10_apartmenSayana.png';
import imgProject11 from './../../assets/projects/11_bintaroMansion.png';
import imgProject12 from './../../assets/projects/12_thamrinNine.png';

import imgProject13 from './../../assets/projects/13_wismaAtletJakarta.png';
import imgProject14 from './../../assets/projects/14_dataCenterBekasiCikarang.png';
import imgProject15 from './../../assets/projects/15_rumahSakitRoyalTaruma.png';
import imgProject16 from './../../assets/projects/16_transparkBekasi.png';
import imgProject17 from './../../assets/projects/17_apartmenRASimatupang.png';
import imgProject18 from './../../assets/projects/18_rumahSakitHarapanKita.png';
import imgProject19 from './../../assets/projects/19_indahKiatPulpPaper.png';
import imgProject20 from './../../assets/projects/20_rusunPikPenggilingan.png';
import imgProject21 from './../../assets/projects/21_apartmenLRTCity.png';
import imgProject22 from './../../assets/projects/22_apartmenCollins.png';
import imgProject23 from './../../assets/projects/23_mallSarinah.png';
import imgProject24 from './../../assets/projects/24_apartmenMahogany.png';

import imgProject25 from './../../assets/projects/25_transparkBintaro.png';
import imgProject26 from './../../assets/projects/26_apartmentTransparkSurabaya.png';
import imgProject27 from './../../assets/projects/27_osakaRiverside.png';
import imgProject28 from './../../assets/projects/28_hotelDomesticTerminal3Soetta.png';
import imgProject29 from './../../assets/projects/29_sekolahSeniTamanIsmailMarzuki.png';
import imgProject30 from './../../assets/projects/30_apartmenJakartaLivingStar.png';
import imgProject31 from './../../assets/projects/31_rusunPoliteknikPUSemarang.png';
import imgProject32 from './../../assets/projects/32_anwaResidence.png';
import imgProject33 from './../../assets/projects/33_paddockMotogpMandalika.png';
import imgProject34 from './../../assets/projects/34_LRTCawang.png';
import imgProject35 from './../../assets/projects/35_31SudirmanSuiteMakassar.png';
import imgProject36 from './../../assets/projects/36_gorPBDjarumBintaro.png';

import imgProject37 from './../../assets/projects/37_apartmenGardeniaBogor.png';
import imgProject38 from './../../assets/projects/38_apartmenParklandSerpong.png';
import imgProject39 from './../../assets/projects/39_apartmenAmazanaSerpong.png';
import imgProject40 from './../../assets/projects/40_apartmenTamanSariIswara.png';
import imgProject41 from './../../assets/projects/41_univUhamkaCiledug.png';
import imgProject42 from './../../assets/projects/42_proyekPtDruLampung.png';
import imgProject43 from './../../assets/projects/43_gapuraAngkasaKemayoran.png';
import imgProject44 from './../../assets/projects/44_perkuatanJembatanKalimantan.png';
import imgProject45 from './../../assets/projects/45_apartmenPavillion.png';

const Projects = () => {
	return (
		<div>
			<div className="relative">
				<div className="relative">
					<div className="absolute w-full h-full bg-black opacity-40 top-0 left-0"></div>
					<img src={projectsBanner} alt="" className="w-full" />
				</div>

				<div className="absolute bottom-[-1.5rem] w-full">
					<div className="lg:container mx-auto flex flex-row justify-end px-4">
						<span className="text-white text-9xl">Projects</span>
					</div>
				</div>
			</div>

			<div className="bg-[#404040]">
				<div className="lg:container mx-auto py-12 px-4">
					<Carousel
						autoPlay={true}
						interval={5000}
						emulateTouch
						infiniteLoop
						stopOnHover
						showStatus={false}
						showArrows={false}
						showIndicators={false}
						thumbWidth={200}
					>
						<div className="grid grid-cols-3 gap-4 select-none px-2">
							<ProjectImages
								image={imgProject1}
								title="Jakarta International Stadium"
							/>
							<ProjectImages image={imgProject2} title="Taman Ismail Marzuki" />
							<ProjectImages
								image={imgProject3}
								title="Bandara Samrat Manado"
							/>
							<ProjectImages
								image={imgProject4}
								title="Apartmen Transpark Bintaro"
							/>
							<ProjectImages image={imgProject5} title="Apartmen Embarcadero" />
							<ProjectImages image={imgProject6} title="Pakubowo Menteng" />
							<ProjectImages image={imgProject7} title="Transpark Cibubur" />
							<ProjectImages image={imgProject8} title="Bintaro XChange 2" />
							<ProjectImages
								image={imgProject9}
								title="Apartmen Sakura Garden"
							/>
							<ProjectImages image={imgProject10} title="Apartmen Sayana" />
							<ProjectImages image={imgProject11} title="Bintaro Mansion" />
							<ProjectImages image={imgProject12} title="Thamrin Nine" />
						</div>

						<div className="grid grid-cols-3 gap-4 select-none px-2">
							<ProjectImages image={imgProject13} title="Wisma Atlet Jakarta" />
							<ProjectImages
								image={imgProject14}
								title="Data Center Bekasi & Cikarang"
							/>
							<ProjectImages
								image={imgProject15}
								title="Rumah Sakit Royal Taruma"
							/>
							<ProjectImages image={imgProject16} title="Transpark Bekasi" />
							<ProjectImages
								image={imgProject17}
								title="Apartmen RA Simatupang"
							/>
							<ProjectImages
								image={imgProject18}
								title="Rumah Sakit Harapan Kita"
							/>
							<ProjectImages
								image={imgProject19}
								title="Indah Kiat Pulp & Paper"
							/>
							<ProjectImages
								image={imgProject20}
								title="Rusun PIK Penggilingan"
							/>
							<ProjectImages
								image={imgProject21}
								title="Apartmen LRT City Ciracas Urban Signature"
							/>
							<ProjectImages image={imgProject22} title="Apartmen Collins" />
							<ProjectImages image={imgProject23} title="Mall Sarinah" />
							<ProjectImages image={imgProject24} title="Apartmen Mahogany" />
						</div>

						<div className="grid grid-cols-3 gap-4 select-none px-2">
							<ProjectImages image={imgProject25} title="Transpark Bintaro" />
							<ProjectImages
								image={imgProject26}
								title="Apartmen Transpark Surabaya"
							/>
							<ProjectImages image={imgProject27} title="Osaka Riverside" />
							<ProjectImages
								image={imgProject28}
								title="Hotel Domestic Terminal 3 Soetta"
							/>
							<ProjectImages
								image={imgProject29}
								title="Sekolah Seni Taman Ismail Marzuki"
							/>
							<ProjectImages
								image={imgProject30}
								title="Apartmen Jakarta Living Star"
							/>
							<ProjectImages
								image={imgProject31}
								title="Rusun Politeknik PU Semarang"
							/>
							<ProjectImages image={imgProject32} title="Anwa Residence" />
							<ProjectImages
								image={imgProject33}
								title="Paddock MotoGP Mandalika"
							/>
							<ProjectImages
								image={imgProject34}
								title="LRT Cawang, Gatsu Kuningan"
							/>
							<ProjectImages
								image={imgProject35}
								title="31 Sudirman Suites Makassar"
							/>
							<ProjectImages
								image={imgProject36}
								title="GOR PB DJarum Bintaro"
							/>
						</div>

						<div className="grid grid-cols-3 gap-4 select-none px-2">
							<ProjectImages
								image={imgProject37}
								title="Apartmen Gardenia Bogor"
							/>
							<ProjectImages
								image={imgProject38}
								title="Apartmen Parkland Serpong"
							/>
							<ProjectImages
								image={imgProject39}
								title="Apartmen Amazana Serpong"
							/>
							<ProjectImages
								image={imgProject40}
								title="Apartmen Taman Sari Iswara"
							/>
							<ProjectImages
								image={imgProject41}
								title="Universitas Uhamka Ciledug"
							/>
							<ProjectImages
								image={imgProject42}
								title="Proyek PT.DRU Lampung"
							/>
							<ProjectImages
								image={imgProject43}
								title="Gapura Angkasa Kemayoran"
							/>
							<ProjectImages
								image={imgProject44}
								title="Perkuatan Jembatan Kalimantan"
							/>
							<ProjectImages image={imgProject45} title="Apartmen Pavillion" />
						</div>
					</Carousel>
				</div>
			</div>
		</div>
	);
};

export default Projects;
