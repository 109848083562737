import React from 'react';

import aboutBanner from './../../assets/about/aboutBanner.png';
import imgWorkers1 from './../../assets/about/workers1.png';
import imgWorkers2 from './../../assets/about/workers2.png';
import imgIndoMap from './../../assets/about/indMap.png';
import imgBgGedung from './../../assets/about/bgGedung.png';

const About = () => {
	return (
		<div>
			<div className="relative">
				<div>
					<img src={aboutBanner} alt="" className="w-full" />
				</div>

				<div className="absolute bottom-[-1.5rem] w-full">
					<div className="lg:container mx-auto flex flex-row justify-end px-4">
						<span className="text-white text-9xl">About Us</span>
					</div>
				</div>
			</div>

			<div className="pt-16 pb-32 relative">
				<div className="w-full h-full absolute top-0 left-0 -z-10">
					<img
						src={imgBgGedung}
						alt=""
						className="h-full w-full object-cover"
					/>
				</div>

				<div className="bg-[#404040] py-16 text-white">
					<div className="lg:container mx-auto flex flex-col gap-4 items-center px-4">
						<span className="text-5xl font-bold">
							PT. ITEWE SARANA KONSTRUKSI
						</span>
						<p className="text-center mx-4 xl:mx-44 2xl:mx-72 text-xl font-light">
							PT. ITEWE SARANA KONSTRUKSI adalah perusahaan nasional yang
							berdiri sejak 2009. ITEWE merupakan bagian dari grup GIAS, yang
							dipercaya sebagai Authorized Dealer berbagai milik swasta di
							Indonesia merek global tepercaya dalam industri kontruksi
							bangunan, dengan misi memastikan penyediaan produk dan pelayanan
							terbaik untuk jajaran pelanggan ITEWE di pasar Indonesia.
						</p>
					</div>
				</div>

				<div className="lg:container mx-auto mt-32 px-4">
					<div className="grid grid-cols-2 gap-16 2xl:gap-32">
						<div>
							<img src={imgWorkers1} alt="" className="mx-auto" />
						</div>

						<div className="flex flex-col gap-4 my-auto">
							<span className="text-3xl">TENAGA YANG BERPENGALAMAN</span>
							<p className="font-light text-lg">
								Memiliki sekelompok personel yang berpengalaman dalam organisasi
								kami, yang berspesialisasi dalam sistem pengembangan, penyediaan
								dan dukungan teknis konstruksi kimia tingkat lanjut teknologi,
								berbagai pengencang dan jangkar kinerja kelas atas, nailer
								inovatif mutakhir dan aplikasi pengeboran premium untuk modern
								apa pun desain & persyaratan struktur beton dan baja bangunan,
								dll. Kami siap dan ingin berkontribusi secara positif dalam
								semua tahapan proyek Anda, mulai dari desain hingga lokasi
								konstruksi dan operasi di sekitar.
							</p>
						</div>

						<div className="flex flex-col gap-4 my-auto">
							<span className="text-3xl">TUJUAN KAMI UNTUK ANDA</span>
							<p className="font-light text-lg">
								Tujuan jangka panjang Anda adalah untuk terus berinovasi dan
								berkontribusi aktif untuk keamanan, solusi total yang kuat,
								cepat namun hemat biaya untuk kebutuhan industri, mendorong
								pendekatan keberlanjutan dan ramah lingkungan.
							</p>
						</div>

						<div>
							<img src={imgWorkers2} alt="" className="mx-auto" />
						</div>
					</div>
				</div>

				<div className="lg:container mx-auto flex flex-col items-center mt-16">
					<img src={imgIndoMap} alt="" />
					<p className="font-light text-xl mx-32 xl:mx-56 text-center">
						<span className="font-bold">ITEWE</span> adalah bagian dari Global
						Indonesia Holding Company Group yang didirikan pada tahun 1998
						dengan cakupan geografis di lebih dari 40 cabang operasional &
						manufaktur di seluruh kepulauan Indonesia
					</p>
				</div>
			</div>
		</div>
	);
};

export default About;
