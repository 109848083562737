// BANNER ITEWE
import imgBannerItewe from './productBanner/iteweBanner.png';

// CATEGORY
import imgCategoryConcreteNailer from './indvProductImg/itewe/category/ITEWE (Concrete Nailer).png';
import imgCategoryChemicalSet from './indvProductImg/itewe/category/ITEWE (Chemical Set).png';
import imgCategoryMechanicalSet from './indvProductImg/itewe/category/ITEWE (Mechanical Set).png';
import imgCategoryDrillBits from './indvProductImg/itewe/category/ITEWE (Drill Bits).png';
import imgCategoryPrecastInsert from './indvProductImg/itewe/category/ITEWE (Precast Insert _ Lifting System).png';
import imgCategoryConstructionSealant from './indvProductImg/itewe/category/ITEWE (Adhesive _ Sealant).png';
import imgCategoryMultiApplicationTools from './indvProductImg/itewe/category/ITEWE (Multi Tools).png';
import imgCategoryCordlessNailer from './indvProductImg/itewe/category/ITEWE (Cordless Li-ion Battery Nailer).png';

// Thumbnail & PDF
import thumbIteweConcreteNailer from '../products/productsThumbPdf/itewe/ITEWE (Concrete Nailer)/ITEWE (Concrete Nailer).png';
import pdfIteweConcreteNailer from '../products/productsThumbPdf/itewe/ITEWE (Concrete Nailer)/ITEWE (Concrete Nailer).pdf';

import thumbIteweChemicalSetNeo from '../products/productsThumbPdf/itewe/ITEWE (Chemical Set)/ITEWE (Chemical Set) NEO1.png';
import pdfIteweChemicalSetNeo from '../products/productsThumbPdf/itewe/ITEWE (Chemical Set)/ITEWE (Chemical Set) NEO1.pdf';
import thumbIteweChemicalSetMX from '../products/productsThumbPdf/itewe/ITEWE (Chemical Set)/MX Capsule.png';
import pdfIteweChemicalSetMX from '../products/productsThumbPdf/itewe/ITEWE (Chemical Set)/ITEWE (Chemical Set) MX Capsule.pdf';
import thumbIteweChemicalDispensingTool from '../products/productsThumbPdf/itewe/ITEWE (Chemical Set)/Dispensing Tool.png';

import thumbIteweMechanicalSetTrubolt from '../products/productsThumbPdf/itewe/ITEWE (Mechanical Set)/ITEWE (Mechanical Set) Trubolt.png';
import pdfIteweMechanicalSetTrubolt from '../products/productsThumbPdf/itewe/ITEWE (Mechanical Set)/ITEWE (Mechanical Set) Trubolt.pdf';
import thumbIteweMechanicalSetSleeveAnchor from '../products/productsThumbPdf/itewe/ITEWE (Mechanical Set)/ITEWE (Mechanical Set) Sleeve Anchor.png';
import pdfIteweMechanicalSetSleeveAnchor from '../products/productsThumbPdf/itewe/ITEWE (Mechanical Set)/ITEWE (Mechanical Set) Sleeve Anchor.pdf';
import thumbIteweMechanicalSetStudbolt from '../products/productsThumbPdf/itewe/ITEWE (Mechanical Set)/Studbolt.png';

import thumbIteweDrillBits from '../products/productsThumbPdf/itewe/ITEWE (Drill Bits)/ITEWE (Drill Bits).png';
import pdfIteweDrillBits from '../products/productsThumbPdf/itewe/ITEWE (Drill Bits)/ITEWE (Drill Bits).pdf';

import thumbItewePrecastInsertFerrules from '../products/productsThumbPdf/itewe/ITEWE (Precast Insert _ Lifting System)/Threaded Ferrules.png';
import pdfItewePrecastInsertFerrules from '../products/productsThumbPdf/itewe/ITEWE (Precast Insert _ Lifting System)/ITEWE (Precast Insert _ Lifting System) Threaded Ferrules.pdf';
import thumbItewePrecastInsertLiftingAnchor from '../products/productsThumbPdf/itewe/ITEWE (Precast Insert _ Lifting System)/Lifting Anchor System.png';
import pdfItewePrecastInsertLiftingAnchor from '../products/productsThumbPdf/itewe/ITEWE (Precast Insert _ Lifting System)/ITEWE (Precast Insert _ Lifting System) Lifting Anchor System.pdf';
import thumbItewePrecastInsertAccessories from '../products/productsThumbPdf/itewe/ITEWE (Precast Insert _ Lifting System)/Accessories.png';
import thumbItewePrecastInsertLiftingClutch from '../products/productsThumbPdf/itewe/ITEWE (Precast Insert _ Lifting System)/Lifting Clutch System.png';

import thumbIteweAdhesiveSealant from '../products/productsThumbPdf/itewe/ITEWE (Adhesive _ Sealant)/ITEWE (Adhesive _ Sealant).png';
import pdfIteweAdhesiveSealant from '../products/productsThumbPdf/itewe/ITEWE (Adhesive _ Sealant)/ITEWE (Adhesive _ Sealant).pdf';
import thumbIteweSealantDispensingTool from '../products/productsThumbPdf/itewe/ITEWE (Adhesive _ Sealant)/Dispensing Tool.png';

// import thumbIteweMultiTools from '../products/productsThumbPdf/itewe/ITEWE (Multi Tools)/ITEWE (Multi Tools).png';
import pdfIteweMultiTools from '../products/productsThumbPdf/itewe/ITEWE (Multi Tools)/ITEWE (Multi Tools).pdf';
import thumbIteweBorskru from '../products/productsThumbPdf/itewe/ITEWE (Multi Tools)/Borskru.png';
import thumbIteweDryWallScrew from '../products/productsThumbPdf/itewe/ITEWE (Multi Tools)/Dry wall Screw.png';
import thumbIteweSrewAutoFeeder from '../products/productsThumbPdf/itewe/ITEWE (Multi Tools)/Screw Auto Feeder.png';

import thumbIteweCordlessNailer from '../products/productsThumbPdf/itewe/ITEWE (Cordless Li-ion Battery Nailer/ITEWE (Cordless Li-ion Battery Nailer).png';
import pdfIteweCordlessNailer from '../products/productsThumbPdf/itewe/ITEWE (Cordless Li-ion Battery Nailer/ITEWE (Cordless Li-ion Battery Nailer.pdf';

const itewe = {
	merek: 'itewe',
	bannerImg: imgBannerItewe,
	bannerPromo: 'Produk Terbaru',
	bannerTitle: 'SABUK HITAM PRO (LIMITED EDITION)',
	bannerDesc:
		'BoSET SABUK HITAM PRO (Limited edition) adalah pengembangan mutakhir dari teknologi BoSET reguler. BoSET Sabuk Hitam pro menawarkan fitur-fitur terbaru yang merupakan komitmen inovasi dari team Riset & Development ITEWE yang akan terus memberikan manfaat-manfaat ekstra bagi pengguna setia BoSET',
	bannerLink: '',
	groupedProducts: [
		{
			groupedProductImg: imgCategoryConcreteNailer,
			groupedProductTitle: 'Concrete Nailer',
			groupedProductDesc:
				'A state-of-the-art Innovation in modern concrete & steel nail fasteners technology, enhancing Professional Practical Construction for Safety, Speed, Power & Cost Efficiencies all in one solutions',
			products: [
				{
					productName: 'Concrete Nailer',
					productThumbnail: thumbIteweConcreteNailer,
					productPdf: pdfIteweConcreteNailer,
				},
			],
		},
		{
			groupedProductImg: imgCategoryChemicalSet,
			groupedProductTitle: 'Chemical Set',
			groupedProductDesc:
				'Value for money solutions for modern chemical construction’s requirement and yet fulfill adequate industrial standars for best application’s quality assurances and affordable project budget',
			products: [
				{
					productName: 'NEO01',
					productThumbnail: thumbIteweChemicalSetNeo,
					productPdf: pdfIteweChemicalSetNeo,
				},
				{
					productName: 'MX Capsule',
					productThumbnail: thumbIteweChemicalSetMX,
					productPdf: pdfIteweChemicalSetMX,
				},
				{
					productName: 'Dispensing Tool',
					productThumbnail: thumbIteweChemicalDispensingTool,
					productPdf: null,
				},
				{
					productName: 'Studbolt',
					productThumbnail: thumbIteweMechanicalSetStudbolt,
					productPdf: null,
				},
			],
		},
		{
			groupedProductImg: imgCategoryMechanicalSet,
			groupedProductTitle: 'Mechanical Set',
			groupedProductDesc:
				'A super-sub options for projects which based on real quality performance expansion mechanical board system with most cost effectiveness yet efficient approach in various ready stock product dimensions',
			products: [
				{
					productName: 'Trubolt',
					productThumbnail: thumbIteweMechanicalSetTrubolt,
					productPdf: pdfIteweMechanicalSetTrubolt,
				},
				{
					productName: 'Sleeve Anchor',
					productThumbnail: thumbIteweMechanicalSetSleeveAnchor,
					productPdf: pdfIteweMechanicalSetSleeveAnchor,
				},
			],
		},

		{
			groupedProductImg: imgCategoryDrillBits,
			groupedProductTitle: 'Drill Bits',
			groupedProductDesc:
				'Manufactured in Euro high-tech equipped automation for quality consistency for best drilling performances',
			products: [
				{
					productName: 'Drill Bits',
					productThumbnail: thumbIteweDrillBits,
					productPdf: pdfIteweDrillBits,
				},
			],
		},

		{
			groupedProductImg: imgCategoryPrecastInsert,
			groupedProductTitle: 'Precast Insert & Lifting System',
			groupedProductDesc:
				"Concrete precaster's best friend from continuous R&D Innovations utilizing enormous expertise, investment and experiences for delivering out its best ever Safe & Practical Effective Precast Lifiting & Insert Fastening",
			products: [
				{
					productName: 'Lifting Anchor System',
					productThumbnail: thumbItewePrecastInsertLiftingAnchor,
					productPdf: pdfItewePrecastInsertLiftingAnchor,
				},
				{
					productName: 'Threaded Ferrules',
					productThumbnail: thumbItewePrecastInsertFerrules,
					productPdf: pdfItewePrecastInsertFerrules,
				},
				{
					productName: 'Lifting Clutch System',
					productThumbnail: thumbItewePrecastInsertLiftingClutch,
					productPdf: null,
				},
				{
					productName: 'Accessories',
					productThumbnail: thumbItewePrecastInsertAccessories,
					productPdf: null,
				},
			],
		},
		{
			groupedProductImg: imgCategoryConstructionSealant,
			groupedProductTitle: 'Construction Grade Sealant & Adhesive',
			groupedProductDesc:
				'High grade advanced patented formulation of construction joint sealant and adhesives technology designed for achieving each best specific project applications and all modern architectural design requirements',
			products: [
				{
					productName: 'Premium Silicone',
					productThumbnail: thumbIteweAdhesiveSealant,
					productPdf: pdfIteweAdhesiveSealant,
				},
				{
					productName: 'Dispensing Tool',
					productThumbnail: thumbIteweSealantDispensingTool,
					productPdf: null,
				},
			],
		},
		{
			groupedProductImg: imgCategoryMultiApplicationTools,
			groupedProductTitle: 'Multi Application Tools',
			groupedProductDesc:
				'Versatile high quality tools, equipments, and accessories for assisting every applicators for bringing out their best expertise project productivity and performance ever with affordable long-lasting quality investments',
			products: [
				// {
				// 	productName: 'Multi Tools',
				// 	productThumbnail: thumbIteweMultiTools,
				// 	productPdf: pdfIteweMultiTools,
				// },
				{
					productName: 'Borskru',
					productThumbnail: thumbIteweBorskru,
					productPdf: pdfIteweMultiTools,
				},
				{
					productName: 'Dry Wall Screw',
					productThumbnail: thumbIteweDryWallScrew,
					productPdf: null,
				},
				{
					productName: 'Screw Auto Feeder',
					productThumbnail: thumbIteweSrewAutoFeeder,
					productPdf: null,
				},
			],
		},
		{
			groupedProductImg: imgCategoryCordlessNailer,
			groupedProductTitle: 'Cordless Li-ion Battery Nailer',
			groupedProductDesc:
				'Highest standard available tool when it comes to requirement of reliable & practical concrete/steel nailing, with advantages of no gas, extra power, safe, low noise and rapid nailing applications, yet lowest operation cost',
			products: [
				{
					productName: 'Cordless Li-ion Battery Nailer',
					productThumbnail: thumbIteweCordlessNailer,
					productPdf: pdfIteweCordlessNailer,
				},
			],
		},
	],
};

export default itewe;
