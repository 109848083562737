import thumbCompanyProfile from '../../assets/downloads/companyProfile.png';
import pdfCompanyProfile from '../../assets/downloads/company profile.pdf';

import thumbRamset from '../../assets/downloads/ramset.png';
import pdfRamset from '../../assets/downloads/ramset.pdf';

import thumbPanduanSabukHitam from '../../assets/downloads/BoSET Sabuk Hitam Pro.png';
import pdfPanduanSabukHitam from '../../assets/downloads/Buku panduan sabuk hitam.pdf';

// DEWALT
import thumbDewaltCordlessConcreteNailer from './../products/productsThumbPdf/dewalt/DEWALT (Cordless Concrete Nailer).png';
import pdfDewaltCordlessConcreteNailer from './../products/productsThumbPdf/dewalt/ITEWE Dewalt.pdf';

// BUILDEX
import thumbBuildexScrew from '../products/productsThumbPdf/buildex/BUILDEX (High Quality Screw).png';
import pdfBuildexScrew from '../products/productsThumbPdf/buildex/ITEWE Buildex.pdf';

// GNET
import thumbGnetMineralInsulation from '../products/productsThumbPdf/gnet/GNET (Mineral Stone Wool Insulations)/GNET (Mineral Stone Wool Insulations).png';
import pdfGnetMineralInsulation from '../products/productsThumbPdf/gnet/GNET (Mineral Stone Wool Insulations)/GNET (Mineral Stone Wool Insulations).pdf';

// ITEWE
import thumbIteweConcreteNailer from '../products/productsThumbPdf/itewe/ITEWE (Concrete Nailer)/ITEWE (Concrete Nailer).png';
import pdfIteweConcreteNailer from '../products/productsThumbPdf/itewe/ITEWE (Concrete Nailer)/ITEWE (Concrete Nailer).pdf';
import thumbIteweChemicalSetNeo from '../products/productsThumbPdf/itewe/ITEWE (Chemical Set)/ITEWE (Chemical Set) NEO1.png';
import pdfIteweChemicalSetNeo from '../products/productsThumbPdf/itewe/ITEWE (Chemical Set)/ITEWE (Chemical Set) NEO1.pdf';
import thumbIteweChemicalSetMX from '../products/productsThumbPdf/itewe/ITEWE (Chemical Set)/MX Capsule.png';
import pdfIteweChemicalSetMX from '../products/productsThumbPdf/itewe/ITEWE (Chemical Set)/ITEWE (Chemical Set) MX Capsule.pdf';
import thumbIteweMechanicalSetTrubolt from '../products/productsThumbPdf/itewe/ITEWE (Mechanical Set)/ITEWE (Mechanical Set) Trubolt.png';
import pdfIteweMechanicalSetTrubolt from '../products/productsThumbPdf/itewe/ITEWE (Mechanical Set)/ITEWE (Mechanical Set) Trubolt.pdf';
import thumbIteweMechanicalSetSleeveAnchor from '../products/productsThumbPdf/itewe/ITEWE (Mechanical Set)/ITEWE (Mechanical Set) Sleeve Anchor.png';
import pdfIteweMechanicalSetSleeveAnchor from '../products/productsThumbPdf/itewe/ITEWE (Mechanical Set)/ITEWE (Mechanical Set) Sleeve Anchor.pdf';
import thumbIteweDrillBits from '../products/productsThumbPdf/itewe/ITEWE (Drill Bits)/ITEWE (Drill Bits).png';
import pdfIteweDrillBits from '../products/productsThumbPdf/itewe/ITEWE (Drill Bits)/ITEWE (Drill Bits).pdf';
import thumbItewePrecastInsertFerrules from '../products/productsThumbPdf/itewe/ITEWE (Precast Insert _ Lifting System)/Threaded Ferrules.png';
import pdfItewePrecastInsertFerrules from '../products/productsThumbPdf/itewe/ITEWE (Precast Insert _ Lifting System)/ITEWE (Precast Insert _ Lifting System) Threaded Ferrules.pdf';
import thumbItewePrecastInsertLiftingAnchor from '../products/productsThumbPdf/itewe/ITEWE (Precast Insert _ Lifting System)/Lifting Anchor System.png';
import pdfItewePrecastInsertLiftingAnchor from '../products/productsThumbPdf/itewe/ITEWE (Precast Insert _ Lifting System)/ITEWE (Precast Insert _ Lifting System) Lifting Anchor System.pdf';
import thumbIteweAdhesiveSealant from '../products/productsThumbPdf/itewe/ITEWE (Adhesive _ Sealant)/ITEWE (Adhesive _ Sealant).png';
import pdfIteweAdhesiveSealant from '../products/productsThumbPdf/itewe/ITEWE (Adhesive _ Sealant)/ITEWE (Adhesive _ Sealant).pdf';
import thumbIteweMultiTools from '../products/productsThumbPdf/itewe/ITEWE (Multi Tools)/ITEWE (Multi Tools).png';
import pdfIteweMultiTools from '../products/productsThumbPdf/itewe/ITEWE (Multi Tools)/ITEWE (Multi Tools).pdf';
import thumbIteweCordlessNailer from '../products/productsThumbPdf/itewe/ITEWE (Cordless Li-ion Battery Nailer/ITEWE (Cordless Li-ion Battery Nailer).png';
import pdfIteweCordlessNailer from '../products/productsThumbPdf/itewe/ITEWE (Cordless Li-ion Battery Nailer/ITEWE (Cordless Li-ion Battery Nailer.pdf';

// RAMSET
import thumbRamsetMechanicalSet from '../products/productsThumbPdf/ramset/mechanical set/RAMSET (Mechanical Set).png';
import pdfRamsetMechanicalSet from '../products/productsThumbPdf/ramset/mechanical set/ITEWE Ramset Brosur Mechanical Set.pdf';
import thumbRamsetChemicalSetEpconG5 from '../products/productsThumbPdf/ramset/chemical set/Epcon G5 Pro-11.png';
import pdfRamsetChemicalSetEpconG5 from '../products/productsThumbPdf/ramset/chemical set/Brosur Epcon G5 Pro.pdf';

// ROCKWOOL
import thumbRockwoolFireInsulation from './../products/productsThumbPdf/rockwool/ROCKWOOL (Fire Acoustic Insulation).png';
import pdfRockwoolFireInsulation from './../products/productsThumbPdf/rockwool/ITEWE Rockwool.pdf';

export const downloads = [
	{
		image: thumbCompanyProfile,
		merek: '',
		pdfName: 'Company Profile',
		pdfFile: pdfCompanyProfile,
	},
	{
		image: thumbPanduanSabukHitam,
		merek: '',
		pdfName: 'BoSET Sabuk Hitam Pro',
		pdfFile: pdfPanduanSabukHitam,
	},
	{
		image: thumbRamset,
		merek: '',
		pdfName: 'Ramset',
		pdfFile: pdfRamset,
	},
	{
		image: thumbIteweConcreteNailer,
		merek: 'ITEWE',
		pdfName: 'Concrete Nailer',
		pdfFile: pdfIteweConcreteNailer,
	},
	{
		image: thumbIteweChemicalSetNeo,
		merek: 'ITEWE',
		pdfName: 'NEO01',
		pdfFile: pdfIteweChemicalSetNeo,
	},
	{
		image: thumbIteweChemicalSetMX,
		merek: 'ITEWE',
		pdfName: 'MX Capsule',
		pdfFile: pdfIteweChemicalSetMX,
	},
	{
		image: thumbIteweMechanicalSetTrubolt,
		merek: 'ITEWE',
		pdfName: 'Trubolt',
		pdfFile: pdfIteweMechanicalSetTrubolt,
	},
	{
		image: thumbIteweMechanicalSetSleeveAnchor,
		merek: 'ITEWE',
		pdfName: 'Sleeve Anchor',
		pdfFile: pdfIteweMechanicalSetSleeveAnchor,
	},
	{
		image: thumbIteweDrillBits,
		merek: 'ITEWE',
		pdfName: 'Drill Bits',
		pdfFile: pdfIteweDrillBits,
	},
	{
		image: thumbItewePrecastInsertFerrules,
		merek: 'ITEWE',
		pdfName: 'Threaded Ferrules',
		pdfFile: pdfItewePrecastInsertFerrules,
	},
	{
		image: thumbItewePrecastInsertLiftingAnchor,
		merek: 'ITEWE',
		pdfName: 'Lifting Anchor System',
		pdfFile: pdfItewePrecastInsertLiftingAnchor,
	},
	{
		image: thumbIteweAdhesiveSealant,
		merek: 'ITEWE',
		pdfName: 'Adhesive Sealant',
		pdfFile: pdfIteweAdhesiveSealant,
	},
	{
		image: thumbIteweMultiTools,
		merek: 'ITEWE',
		pdfName: 'Multi Tools',
		pdfFile: pdfIteweMultiTools,
	},
	{
		image: thumbIteweCordlessNailer,
		merek: 'ITEWE',
		pdfName: 'Cordless Li-ion Battery Nailer',
		pdfFile: pdfIteweCordlessNailer,
	},
	{
		image: thumbDewaltCordlessConcreteNailer,
		merek: 'DEWALT',
		pdfName: 'Cordless Concrete Nailer',
		pdfFile: pdfDewaltCordlessConcreteNailer,
	},
	{
		image: thumbBuildexScrew,
		merek: 'BUILDEX',
		pdfName: 'High Quality Industrial Screw',
		pdfFile: pdfBuildexScrew,
	},
	{
		image: thumbGnetMineralInsulation,
		merek: 'GNET',
		pdfName: 'Mineral Stone Wool Insulations',
		pdfFile: pdfGnetMineralInsulation,
	},
	{
		image: thumbRamsetMechanicalSet,
		merek: 'RAMSET',
		pdfName: 'Mechanical Set',
		pdfFile: pdfRamsetMechanicalSet,
	},
	{
		image: thumbRamsetChemicalSetEpconG5,
		merek: 'RAMSET',
		pdfName: 'Epcon G5',
		pdfFile: pdfRamsetChemicalSetEpconG5,
	},
	{
		image: thumbRockwoolFireInsulation,
		merek: 'ROCKWOOL',
		pdfName: 'Fire & Acoustic Insulation',
		pdfFile: pdfRockwoolFireInsulation,
	},
];
