import React from 'react';

import eventsBanner from './../../assets/events/eventsBanner.png';

import indoBuildTech1 from './../../assets/events/indobuildtech/indobuildtech-1.png';
import indoBuildTech2 from './../../assets/events/indobuildtech/indobuildtech-2.png';
import indoBuildTech3 from './../../assets/events/indobuildtech/indobuildtech-3.png';
import indoBuildTech4 from './../../assets/events/indobuildtech/indobuildtech-4.png';
import indoBuildTech5 from './../../assets/events/indobuildtech/indobuildtech-5.png';
import indoBuildTech6 from './../../assets/events/indobuildtech/indobuildtech-6.png';

const Events = () => {
	return (
		<div>
			<div className="relative overflow-hidden">
				<div className="relative">
					<div className="absolute w-full h-full bg-black opacity-40 top-0 left-0"></div>
					<img src={eventsBanner} alt="" className="w-full" />
				</div>

				<div className="absolute bottom-[-1.5rem] w-full">
					<div className="lg:container mx-auto flex flex-row justify-end px-4">
						<span className="text-white text-9xl">Events</span>
					</div>
				</div>
			</div>

			<div className="bg-[#404040]">
				<div className="lg:container mx-auto py-16 px-4">
					<span className="font-bold text-5xl text-[#e4e4e4]">
						PAMERAN INDOBUILDTECH 2022
					</span>
					<div className="grid grid-cols-3 items-center gap-8 mt-12">
						<img src={indoBuildTech1} alt="" className="w-full h-auto" />
						<img src={indoBuildTech2} alt="" className="w-full h-auto" />
						<img src={indoBuildTech3} alt="" className="w-full h-auto" />
						<img src={indoBuildTech4} alt="" className="w-full h-auto" />
						<img src={indoBuildTech5} alt="" className="w-full h-auto" />
						<img src={indoBuildTech6} alt="" className="w-full h-auto" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Events;
