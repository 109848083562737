import React from 'react';

import olshopBanner from './../../assets/olshop/olshopBanner.png';

import tokopediaLogo from './../../assets/olshop/tokopediaLogo.png';
import shopeeLogo from './../../assets/olshop/shopeeLogo.png';
import bukalapakLogo from './../../assets/olshop/bukalapakLogo.png';

const Olshop = () => {
	return (
		<div>
			<div className="relative">
				<div className="relative">
					<div className="absolute w-full h-full bg-black opacity-40 top-0 left-0"></div>
					<img src={olshopBanner} alt="" className="w-full" />
				</div>

				<div className="absolute bottom-[-1.5rem] w-full">
					<div className="lg:container mx-auto flex flex-row justify-end px-4">
						<span className="text-white text-9xl">Where to Buy</span>
					</div>
				</div>
			</div>

			<div className="lg:container mx-auto flex flex-row gap-32 items-center justify-center my-32 px-4">
				<div className="flex flex-col items-center gap-6">
					<img src={tokopediaLogo} alt="" className="w-64 h-auto" />
					<a
						href="https://www.tokopedia.com/itewe"
						target="_blank"
						rel="noreferrer"
					>
						<span className="bg-[#F16213] text-white  text-xl w-fit px-4 py-2 rounded-full mt-6">
							Beli Sekarang
						</span>
					</a>
				</div>

				<div className="flex flex-col items-center gap-6">
					<img src={shopeeLogo} alt="" className="w-64 h-auto" />
					<a
						href="https://shopee.co.id/itewe_sarana_konstruksi"
						target="_blank"
						rel="noreferrer"
					>
						<span className="bg-[#F16213] text-white text-xl w-fit px-4 py-2 rounded-full mt-6">
							Beli Sekarang
						</span>
					</a>
				</div>

				<div className="flex flex-col items-center gap-6">
					<img src={bukalapakLogo} alt="" className="w-64 h-auto" />
					<a
						href="https://www.bukalapak.com/u/pt_itewe_sarana_konstruksi"
						target="_blank"
						rel="noreferrer"
					>
						<span className="bg-[#F16213] text-white text-xl w-fit px-4 py-2 rounded-full mt-6">
							Beli Sekarang
						</span>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Olshop;
