import React from 'react';

import downloadsBanner from '../../assets/downloads/downloadsBanner.png';
import downloadIcon from '../../assets/downloads/downloadIcon.png';

import { downloads } from '../../models/downloads/downloads';

const Downloads = () => {
	return (
		<div>
			<div className="relative overflow-hidden">
				<div className="relative">
					<div className="absolute w-full h-full bg-black opacity-40 top-0 left-0"></div>
					<img src={downloadsBanner} alt="" className="w-full" />
				</div>

				<div className="absolute bottom-[-1.5rem] w-full">
					<div className="lg:container mx-auto flex flex-row justify-end px-4">
						<span className="text-white text-9xl">Downloads</span>
					</div>
				</div>
			</div>

			<div className="lg:container mx-auto mt-24 mb-16 px-4">
				<div className="grid grid-cols-3 items-start gap-8 xl:grid-cols-3 xl:gap-16">
					{downloads.map((item) => {
						return (
							<a href={item.pdfFile} target="_blank" rel="noreferrer">
								<div className="">
									<div className="w-full px-12 py-4 xl:px-24 xl:py-8 bg-[#D5D5D5]">
										<img src={item.image} alt="" className="w-full h-auto" />
									</div>

									<div className="bg-[#F16213] w-full px-8 py-4 flex flex-row justify-between items-center">
										<div className="flex flex-col">
											<span className="text-white font-bold text-lg xl:text-2xl">
												{item.merek}
											</span>
											<span className="text-white font-bold text-2xl xl:text-3xl">
												{item.pdfName}
											</span>
										</div>

										<img src={downloadIcon} alt="" className="h-12 w-auto" />
									</div>
								</div>
							</a>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Downloads;
