import buildex from './products/buildex';
import dewalt from './products/dewalt';
import gnet from './products/gnet';
import itewe from './products/itewe';
import ramset from './products/ramset';
import rockwool from './products/rockwool';

const products = [itewe, ramset, dewalt, rockwool, buildex, gnet];

export default products;
