import React from 'react';

import imgBanner1 from '../../assets/home/banners/banner1.png';
import imgBanner2 from '../../assets/home/banners/banner2.png';
import imgBanner3 from '../../assets/home/banners/banner3.png';

// import thumbIteweCordlessNailer from '../products/productsThumbPdf/itewe/ITEWE (Cordless Li-ion Battery Nailer/ITEWE (Cordless Li-ion Battery Nailer).png';

import pdfPanduanSabukHitam from '../../assets/downloads/Buku panduan sabuk hitam.pdf';
import pdfIteweCordlessNailer from '../../models/products/productsThumbPdf/itewe/ITEWE (Cordless Li-ion Battery Nailer/ITEWE (Cordless Li-ion Battery Nailer.pdf';
import pdfIteweChemicalSetNeo from '../../models/products/productsThumbPdf/itewe/ITEWE (Chemical Set)/ITEWE (Chemical Set) NEO1.pdf';

import imgProducts from '../../assets/home/all-products.png';
import imgBuildings from '../../assets/home/buildings.png';
import imgOriginal from '../../assets/home/original.png';
import imgGaransi from '../../assets/home/garansi.png';
import imgSertifikasi from '../../assets/home/certificationsImg.png';

import imgBoset from '../../assets/home/boset.png';
import imgBorskru from '../../assets/home/borskru.png';
import imgNeo from '../../assets/home/neo.png';
import imgTrubolt from '../../assets/home/trubolt.png';

import imgIteweCircle from '../../assets/home/iteweCircle.png';
import { Carousel } from 'react-responsive-carousel';

const Home = () => {
	return (
		<div className="">
			{/* Banner */}

			<Carousel
				autoPlay={true}
				interval={5000}
				emulateTouch
				infiniteLoop
				stopOnHover
				showStatus={false}
				showArrows={true}
				showIndicators={false}
				swipeable={false}
			>
				<div className="relative">
					<div className="bg-black w-full h-full">
						<img src={imgBanner1} alt="" className="w-full h-auto" />
					</div>

					<div className="absolute top-0 w-full h-full flex flex-row items-center">
						<div className="bg-black opacity-80 h-full absolute top-0 left-0 w-[40%] xl:w-[35%]"></div>
						<div className="lg:container mx-auto text-white flex flex-col z-10 w-full pl-4">
							<span className="text-3xl font-bold text-left inline-block">
								BOSET SABUK HITAM PRO
							</span>
							<span className="max-w-sm font-light text-2xl text-left inline-block">
								BoSET dengan pengembangan mutakhir dari teknologi BoSET Reguler
							</span>

							<a
								href={pdfPanduanSabukHitam}
								target="_blank"
								rel="noreferrer"
								className="bg-[#F16213] text-white w-fit px-4 py-2 rounded-full mt-6"
							>
								Lihat Selengkapnya
							</a>
						</div>
					</div>
				</div>

				<div className="relative">
					<div className="bg-black w-full h-full">
						<img src={imgBanner2} alt="" className="w-full h-auto" />
					</div>

					<div className="absolute top-0 w-full h-full flex flex-row items-center">
						<div className="bg-black opacity-80 h-full absolute top-0 left-0 w-[40%] xl:w-[35%]"></div>
						<div className="lg:container mx-auto text-white flex flex-col z-10 w-full pl-4">
							<span className="text-3xl font-bold text-left inline-block">
								CASS
							</span>
							<span className="max-w-sm font-light text-2xl text-left inline-block">
								Pemaku baterai tanpa kabel yang tidak memerlukan gas, selang,
								kabel, selang, dan kompresor udara sehingga sangat efektif
							</span>

							<a
								href={pdfIteweCordlessNailer}
								target="_blank"
								rel="noreferrer"
								className="bg-[#F16213] text-white w-fit px-4 py-2 rounded-full mt-6"
							>
								Lihat Selengkapnya
							</a>
						</div>
					</div>
				</div>

				<div className="relative">
					<div className="bg-black w-full h-full">
						<img src={imgBanner3} alt="" className="w-full h-auto " />
					</div>

					<div className="absolute top-0 w-full h-full flex flex-row items-center">
						<div className="bg-black opacity-80 h-full absolute top-0 left-0 w-[40%] xl:w-[35%]"></div>
						<div className="lg:container mx-auto text-white flex flex-col z-10 w-full pl-4">
							<span className="text-3xl font-bold text-left inline-block">
								NEO1
							</span>
							<span className="max-w-sm font-light text-2xl text-left inline-block">
								Telah teruji pada suhu temperatur yang tinggi dan terjamin
							</span>

							<a
								href={pdfIteweChemicalSetNeo}
								target="_blank"
								rel="noreferrer"
								className="bg-[#F16213] text-white w-fit px-4 py-2 rounded-full mt-6"
							>
								Lihat Selengkapnya
							</a>
						</div>
					</div>
				</div>
			</Carousel>

			{/* Produk Pilihan */}
			<div className="lg:container mx-auto mt-16 mb-32 px-4">
				<span className="text-5xl font-extrabold tracking-tight">
					Produk Pilihan
				</span>

				<div className="grid grid-cols-4 gap-4 w-full mt-8 mb-16 ">
					<div className="relative w-full flex flex-col">
						<img
							src={imgBoset}
							alt=""
							className="w-full h-auto border-[#404040] border-2 p-8"
						/>

						<div className=" bg-[#404040] text-white px-3 py-3 h-full ">
							<span className="text-3xl font-bold">BOSET</span>
							<p className="font-light ">
								Inovasi baru dalam dunia paku tembak. Dengan fitur yang modern
								dan praktis.
							</p>
						</div>
					</div>

					<div className="relative w-full flex flex-col">
						<img
							src={imgBorskru}
							alt=""
							className="w-full h-auto border-[#404040] border-2 p-8 "
						/>

						<div className="bg-[#404040] text-white px-3 py-3 h-full">
							<span className="text-3xl font-bold">BORSKRU</span>
							<p className="font-light ">
								Bor serbaguna berkualitas tinggi untuk membantu setiap aplikasi.
							</p>
						</div>
					</div>

					<div className="relative w-full flex flex-col">
						<img
							src={imgNeo}
							alt=""
							className="w-full h-auto border-[#404040] border-2 p-8"
						/>

						<div className=" bg-[#404040] text-white px-3 py-3 h-full">
							<span className="text-3xl font-bold">NEO1</span>
							<p className="font-light ">
								Solusi yang bernilai untuk persyaratan konstruksi kimia modern.
							</p>
						</div>
					</div>

					<div className="relative w-full flex flex-col">
						<img
							src={imgTrubolt}
							alt=""
							className="w-full h-auto border-[#404040] border-2 p-8"
						/>

						<div className=" bg-[#404040] text-white px-3 py-3 h-full">
							<span className="text-3xl font-bold">TRUBOLT</span>
							<p className="font-light ">
								Opsi super untuk proyek yang didasarkan pada kinerja kualitas
								nyata dan efektifitas tinggi.
							</p>
						</div>
					</div>
				</div>

				<div className="flex flex-row justify-end">
					<a
						href="/products"
						className="bg-[#F16213] text-white w-fit px-4 py-2 rounded-full"
					>
						Lihat Selengkapnya
					</a>
				</div>
			</div>

			{/* Mengapa harus ITEWE */}
			<div className="lg:container mx-auto my-8 px-4">
				<span className="text-7xl font-extrabold tracking-tight">
					Mengapa Harus ITEWE?
				</span>

				<div className="grid grid-cols-2 gap-16 justify-center my-12">
					<div>
						<img src={imgProducts} alt="" className="mx-auto" />
					</div>

					<div className="flex flex-col gap-4 my-auto">
						<span className="text-3xl">MENAWARKAN BANYAK PRODUK</span>
						<p className="font-light text-lg">
							Kami menawarkan berbagai produk dari brand terkemuka seperti
							Ramset, GNET, Rockwool, Buildex, bahkan Dewalt. Kami juga
							menghadirkan berbagai macam produk yang diproduksi oleh ITEWE
							dengan kualitas yang tidak kalah bagus dan tahan lama sehingga
							dapat membantu memudahkan pekerjaan konstruksi anda.
						</p>
					</div>

					<div className="flex flex-col gap-4 my-auto">
						<span className="text-3xl">BANYAK REFERENSI PROYEK</span>
						<p className="font-light text-lg">
							Sudah banyak proyek atau konstruksi yang menggunakan produk yang
							kami tawarkan seperti Jakarta International Stadium (JIS), Taman
							Ismail Marzuki, Apartment Sakura Garden City, dan lain-lainnya.
							Untuk selengkapnya anda dapat melihat melalui opsi "Project &
							Events"
						</p>
					</div>

					<div>
						<img src={imgBuildings} alt="" className="mx-auto" />
					</div>
					<div>
						<img src={imgSertifikasi} alt="" className="mx-auto my-32" />
					</div>

					<div className="flex flex-col gap-4 my-auto">
						<span className="text-3xl">SUDAH LOLOS UJI</span>
						<p className="font-light text-lg">
							Produk yang kami tawarkan bukan sembarang produk namun sudah diuji
							oleh berbagai tahap uji sehingga aman dan tahan lama ketika
							digunakan. Oleh karena itu, kualitasnya sudah terjamin yang
							terbaik untuk anda.
						</p>
					</div>

					<div className="flex flex-col gap-4 my-auto">
						<span className="text-3xl">TERDAPAT GARANSI</span>
						<p className="font-light text-lg">
							Tujuan kami adalah memberikan kepuasaan kepada pelanggan sehingga
							kami memberikan garansi resmi kepada produk yang anda beli
							sehingga memberi kenyamanan dan keamanan ketika terdapat kesalahan
							pada produk.
						</p>
					</div>

					<div>
						<img src={imgGaransi} alt="" className="mx-auto max-w-sm" />
					</div>
					<div>
						<img src={imgOriginal} alt="" className="mx-auto max-w-md" />
					</div>

					<div className="flex flex-col gap-4 my-auto">
						<span className="text-3xl">ORIGINAL 100%</span>
						<p className="font-light text-lg">
							Produk yang kami tawarkan bukan produk palsu atau KW, melainkan
							produk asli 100%. Kami ingin memberikan produk yang terbaik untuk
							anda sehingga dapat membantu pekerjaan konstruksi anda.
						</p>
					</div>
				</div>
			</div>

			{/* SOLUSI KONSTRUKSI INDONESIA */}
			<div className=" bg-[#404040]">
				<span className="text-6xl xl:text-7xl text-center block py-24 text-[#E46C0A]">
					SOLUSI KONSTRUKSI INDONESIA
				</span>
			</div>

			{/* ITEWE FULL CIRCLE TOTAL SOLUTION */}
			<div className="w-full">
				<img src={imgIteweCircle} alt="" className="" />
			</div>
		</div>
	);
};

export default Home;
