import React from 'react';

import certificationsBanner from '../../assets/certifications/certificationsBanner.png';
import certificationsImg from '../../assets/certifications/certificationsImg.png';
import bosetmanImg from '../../assets/certifications/bosetMan.png';

const Certifications = () => {
	return (
		<div>
			<div className="relative">
				<div className="relative">
					<div className="absolute w-full h-full bg-black opacity-40 top-0 left-0"></div>
					<img src={certificationsBanner} alt="" className="w-full" />
				</div>

				<div className="absolute bottom-[-1.5rem] w-full">
					<div className="lg:container mx-auto flex flex-row justify-end px-4">
						<span className="text-white text-9xl">Certifications</span>
					</div>
				</div>
			</div>

			<div className="lg:container mx-auto flex flex-row gap-2 items-center justify-between mt-8 px-4">
				<img src={certificationsImg} alt="" className="w-[70%]" />
				<img src={bosetmanImg} alt="" className="w-[30%]" />
			</div>
		</div>
	);
};

export default Certifications;
