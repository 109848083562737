import React from 'react';

const ProjectImages = (props) => {
	const image = props.image;
	const title = props.title;

	return (
		<div className="relative">
			<img
				src={image}
				alt=""
				className="w-full h-auto object-bottom aspect-video"
			/>

			<span className="absolute bottom-5 left-0 uppercase text-white bg-[#F16213] px-2 py-1 text-left">
				{title}
			</span>
		</div>
	);
};

export default ProjectImages;
