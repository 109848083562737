// BANNER RAMSET
import imgBannerRamset from './productBanner/ramsetBanner.png';

// CATEGORY
import imgCategoryChemicalSet from './indvProductImg/ramset/category/RAMSET (Chemical Set).png';
import imgCategoryMechanicalSet from './indvProductImg/ramset/category/RAMSET (Mechanical Set).png';

// Thumbnail & PDF
import thumbRamsetMechanicalSet from '../products/productsThumbPdf/ramset/mechanical set/RAMSET (Mechanical Set).png';
import pdfRamsetMechanicalSet from '../products/productsThumbPdf/ramset/mechanical set/ITEWE Ramset Brosur Mechanical Set.pdf';

import thumbRamsetChemicalSetEpconG5 from '../products/productsThumbPdf/ramset/chemical set/Epcon G5 Pro-11.png';
import pdfRamsetChemicalSetEpconG5 from '../products/productsThumbPdf/ramset/chemical set/Brosur Epcon G5 Pro.pdf';
import thumbRamsetChemicalSetDispensingTool from '../products/productsThumbPdf/ramset/chemical set/Dispensing Tool.png';
import thumbRamsetChemicalSetManualPump from '../products/productsThumbPdf/ramset/chemical set/Manual Pump.png';

import thumbRamsetStandardAnchorStud from '../products/productsThumbPdf/ramset/chemical set/Standard Anchor Stud.png';

const ramset = {
	merek: 'ramset',
	bannerImg: imgBannerRamset,
	bannerPromo: 'Produk Favorit',
	bannerTitle: 'EPCON G5 PRO',
	bannerDesc:
		'Epoksi penahan kinerja tinggi untuk beton yang disetujui untuk beton retak & zona seismik',
	bannerLink: '',
	groupedProducts: [
		{
			groupedProductImg: imgCategoryChemicalSet,
			groupedProductTitle: 'Chemical Set',
			groupedProductDesc:
				'Company of more than 70 years experienced Brand under ITW Group with global presences, supported by engineers expertise for world best performance chemical anchor systems with international test approvals',
			products: [
				{
					productName: 'Epcon G5',
					productThumbnail: thumbRamsetChemicalSetEpconG5,
					productPdf: pdfRamsetChemicalSetEpconG5,
				},
				{
					productName: 'Dispensing Tool',
					productThumbnail: thumbRamsetChemicalSetDispensingTool,
					productPdf: null,
				},
				{
					productName: 'Manual Pump',
					productThumbnail: thumbRamsetChemicalSetManualPump,
					productPdf: null,
				},
				{
					productName: 'Standard Anchor Stud',
					productThumbnail: thumbRamsetStandardAnchorStud,
					productPdf: null,
				},
			],
		},
		{
			groupedProductImg: imgCategoryMechanicalSet,
			groupedProductTitle: 'Mechanical Set',
			groupedProductDesc:
				'By the famous invention of Dynabolt, Ramset continues to develop full functional mechanical anchors for all construction’s needs, which valued on Safe, Speedy and Powerful solutions with international test approvals',
			products: [
				{
					productName: 'Mechanical Set',
					productThumbnail: thumbRamsetMechanicalSet,
					productPdf: pdfRamsetMechanicalSet,
				},
			],
		},
	],
};

export default ramset;
