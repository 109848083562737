import React, { useState } from 'react';

import iteweLogo from './../../assets/products/logo/iteweLogo.png';
import ramsetLogo from './../../assets/products/logo/ramsetLogo.png';
import dewaltLogo from './../../assets/products/logo/dewaltLogo.png';
import rockwoolLogo from './../../assets/products/logo/rockwoolLogo.png';
import buildexLogo from './../../assets/products/logo/buildexLogo.png';
import gnetLogo from './../../assets/products/logo/gnetLogo.png';

import iteweImg from './../../assets/products/img/iteweImg.png';
import ramsetImg from './../../assets/products/img/ramsetImg.png';
import dewaltImg from './../../assets/products/img/dewaltImg.png';
import rockwoolImg from './../../assets/products/img/rockwoolImg.png';
import buildexImg from './../../assets/products/img/buildexImg.png';
import gnetImg from './../../assets/products/img/gnetImg.png';

import productsBanner from './../../assets/products/productsBanner.png';

// HALAMAN BUAT NAMPILIN SEMUA MEREK BARANG

const Products = () => {
	let [isIteweHovered, setIsIteweHovered] = useState(false);
	let [isRamsetHovered, setIsRamsetHovered] = useState(false);
	let [isDewaltHovered, setIsDewaltHovered] = useState(false);
	let [isRockwoolHovered, setIsRockwoolHovered] = useState(false);
	let [isBuildexHovered, setIsBuildexHovered] = useState(false);
	let [isGnetHovered, setIsGnetHovered] = useState(false);

	return (
		<div>
			<div className="relative">
				<div className="relative">
					<div className="absolute w-full h-full bg-black opacity-40 top-0 left-0"></div>
					<img src={productsBanner} alt="" className="w-full" />
				</div>

				<div className="absolute bottom-[-1.5rem] w-full">
					<div className="lg:container mx-auto flex flex-row justify-end px-4">
						<span className="text-white text-9xl">Products</span>
					</div>
				</div>
			</div>

			<div className="flex flex-col my-16">
				{/* RAMSET */}
				<a
					href="/products/ramset"
					className="block"
					onMouseEnter={() => setIsRamsetHovered(true)}
					onMouseLeave={() => setIsRamsetHovered(false)}
				>
					<div className="flex flex-row w-full items-center bg-[#D3743E]">
						<img src={ramsetLogo} alt="" className="px-12 w-[30%]" />

						<div className="w-[70%] relative overflow-hidden">
							<img src={ramsetImg} alt="" className="w-full" />

							<div
								className={`absolute top-0 left-0 w-full h-full flex justify-center items-center px-16 transition-transform transform ${
									isRamsetHovered ? 'translate-x-0' : 'translate-x-full'
								}`}
							>
								<div className="bg-[#D3743E] absolute top-0 left-0 w-full h-full opacity-60"></div>

								<span className="text-white  text-xl xl:text-3xl z-10">
									Lebih dari 70 tahun berpengalaman Merek di bawah Grup ITW
									dengan kehadiran global, didukung oleh insinyur dan keahlian
									teknis untuk sistem jangkar kimia kinerja terbaik dunia.
								</span>
							</div>
						</div>
					</div>
				</a>

				{/* ITEWE */}
				<a
					href="/products/itewe"
					className="block"
					onMouseEnter={() => setIsIteweHovered(true)}
					onMouseLeave={() => setIsIteweHovered(false)}
				>
					<div className="flex flex-row w-full items-center">
						<img src={iteweLogo} alt="" className="px-12 w-[30%]" />

						<div className="w-[70%] relative overflow-hidden">
							<img src={iteweImg} alt="" className="w-full" />

							<div
								className={`absolute top-0 left-0 w-full h-full flex justify-center items-center px-16 transition-transform transform ${
									isIteweHovered ? 'translate-x-0' : 'translate-x-full'
								}`}
							>
								<div className="bg-[#F16213] absolute top-0 left-0 w-full h-full opacity-60"></div>

								<span className="text-white text-xl xl:text-3xl z-10 ">
									Sahabat pracetak beton yang menginginkan solusi pengangkatan
									yang aman, cepat, dan anda, baik di bengkel maupun di lokasi
									proyek. Hasil dari aktivitas R&D perusaahaan yang
									terus-menerus memanfaatkan keahlian, investasi, dan pengalaman
									yang luar biasa untuk menghasilkan yang terbaik.
								</span>
							</div>
						</div>
					</div>
				</a>

				{/* BUILDEX */}
				<a
					href="/products/buildex"
					className="block"
					onMouseEnter={() => setIsBuildexHovered(true)}
					onMouseLeave={() => setIsBuildexHovered(false)}
				>
					<div className="flex flex-row w-full items-center bg-[#FFD3D3]">
						<img src={buildexLogo} alt="" className="px-12 w-[30%]" />

						<div className="w-[70%] relative overflow-hidden">
							<img src={buildexImg} alt="" className="w-full" />

							<div
								className={`absolute top-0 left-0 w-full h-full flex justify-center items-center px-16 transition-transform transform ${
									isBuildexHovered ? 'translate-x-0' : 'translate-x-full'
								}`}
							>
								<div className="bg-[#FFD3D3] absolute top-0 left-0 w-full h-full opacity-60"></div>

								<span className="text-white  text-xl xl:text-3xl z-10">
									Satu-satunya produsen self-drilling di Australia. Tingkat
									kontrol manufaktur dan komitmen terhadap kualitas telah
									membuat pengencang Buildex memiliki reputasi memberikan
									pengencangan atap yang paling dapat diandalkan di pasar.
								</span>
							</div>
						</div>
					</div>
				</a>

				{/* DEWALT */}
				<a
					href="/products/dewalt"
					className="block"
					onMouseEnter={() => setIsDewaltHovered(true)}
					onMouseLeave={() => setIsDewaltHovered(false)}
				>
					<div className="flex flex-row w-full items-center bg-[#FEBD18]">
						<img src={dewaltLogo} alt="" className="px-12 w-[30%]" />

						<div className="w-[70%] relative overflow-hidden">
							<img src={dewaltImg} alt="" className="w-full" />

							<div
								className={`absolute top-0 left-0 w-full h-full flex justify-center items-center px-16 transition-transform transform ${
									isDewaltHovered ? 'translate-x-0' : 'translate-x-full'
								}`}
							>
								<div className="bg-[#FEBD18] absolute top-0 left-0 w-full h-full opacity-60"></div>

								<span className="text-white  text-xl xl:text-3xl z-10">
									Produsen perkakas tangan, perkakas listrik, aksesoris
									terkemuka, dan merek andalan dari Stanley Black & Decker
									Corporation. DEWALT berkomitmen untuk menyediakan produk yang
									unggul, tangguh, dan sesuai dengan kebutuhan para profesional
									wilayah kerja berat di seluruh dunia.
								</span>
							</div>
						</div>
					</div>
				</a>

				{/* ROCKWOOL */}
				<a
					href="/products/rockwool"
					className="block"
					onMouseEnter={() => setIsRockwoolHovered(true)}
					onMouseLeave={() => setIsRockwoolHovered(false)}
				>
					<div className="flex flex-row w-full items-center bg-[#C6000E]">
						<img src={rockwoolLogo} alt="" className="px-12 w-[30%]" />

						<div className="w-[70%] relative overflow-hidden">
							<img src={rockwoolImg} alt="" className="w-full" />

							<div
								className={`absolute top-0 left-0 w-full h-full flex justify-center items-center px-16 transition-transform transform ${
									isRockwoolHovered ? 'translate-x-0' : 'translate-x-full'
								}`}
							>
								<div className="bg-[#C6000E] absolute top-0 left-0 w-full h-full opacity-60"></div>

								<span className="text-white  text-xl xl:text-3xl z-10">
									Solusi merek terpercaya terbaik untuk semua dalam satu
									Insulasi api, termal & akustik yang aman dengan berbagai
									pilihan produk berbasis aplikasi dengan laporan pengujian lab
									yang komprehensif & persetujuan internasional serta dukungan
									teknis yang tak terkalahkan.
								</span>
							</div>
						</div>
					</div>
				</a>

				{/* GNET */}
				<a
					href="/products/gnet"
					className="block"
					onMouseEnter={() => setIsGnetHovered(true)}
					onMouseLeave={() => setIsGnetHovered(false)}
				>
					<div className="flex flex-row w-full items-center">
						<img src={gnetLogo} alt="" className="px-12 w-[30%]" />

						<div className="w-[70%] relative overflow-hidden">
							<img src={gnetImg} alt="" className="w-full" />

							<div
								className={`absolute top-0 left-0 w-full h-full flex justify-center items-center px-16 transition-transform transform ${
									isGnetHovered ? 'translate-x-0' : 'translate-x-full'
								}`}
							>
								<div className="bg-[#136FB6] absolute top-0 left-0 w-full h-full opacity-60"></div>

								<span className="text-white  text-xl xl:text-3xl z-10">
									Perusahaan distribusi berskala nasional yang menyediakan
									produk bahan bangunan berkualitas dan terlengkap dengan
									menjunjung tinggi kepuasan pelanggan.
								</span>
							</div>
						</div>
					</div>
				</a>
			</div>
		</div>
	);
};

export default Products;
