import React from 'react';

import contactusBanner from './../../assets/contactus/contactusBanner.png';

const ContactUs = () => {
	return (
		<div>
			<div className="relative">
				<div className="relative">
					<div className="absolute w-full h-full bg-black opacity-40 top-0 left-0"></div>
					<img src={contactusBanner} alt="" className="w-full" />
				</div>

				<div className="absolute bottom-[-1.5rem] w-full">
					<div className="lg:container mx-auto flex flex-row justify-end px-4">
						<span className="text-white text-9xl">Contact Us</span>
					</div>
				</div>
			</div>

			<div className="lg:container mx-auto flex flex-row items-start my-16 px-4 gap-8 xl:gap-16">
				<div className="w-[45%] xl:w-[35%]">
					<iframe
						title="Itewe Maps"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d123322.8817541746!2d106.76117188661532!3d-6.1619982171072305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f58d71272beb%3A0x6efb8e891b307691!2sPT%20Itewe%20Sarana%20Konstruksi!5e0!3m2!1sid!2sid!4v1696484508897!5m2!1sid!2sid"
						className="w-full h-auto aspect-[5/4]"
						style={{ border: 0 }}
						allowfullscreen=""
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
					></iframe>
				</div>

				<div className="flex flex-col w-[55%] xl:w-[65%] text-[#707070]">
					<div className="flex flex-col">
						<span className="font-bold text-5xl">KAMI HADIR!</span>
						<span className="text-xl font-light mt-4">
							Jl. Sunter Permai Raya Perumahan Punter Parden. Blok D-8 Kav.3E,
							RT.5/RW.18, Sunter Agung, Kec. Tj. Priok, Jkt Utara, Daerah Khusus
							Ibukota Jakarta, 14350
						</span>
						<span className="text-xl font-bold mt-8">
							Senin - Jumat (08.00 AM - 17.00 PM)
						</span>
					</div>

					<div className="flex flex-col mt-16">
						<span className="font-bold text-3xl">HUBUNGI KAMI</span>
						<span className="font-light text-md mt-4">Tlp (021) 645 5319</span>
						<span className="font-light text-md">Fax (021) 645 5319</span>
						<span className="font-light text-md">TechCall 0822 8000 4840</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactUs;
