import React from 'react';
import { useParams } from 'react-router';
import products from '../../models/products.js';
import LeftArrowIcon from '../../assets/icon/left-arrow.png';

// HALAMAN KATEGORI PRODUK SETELAH GROUPING PRODUK
// ADA TAMPILIN INDIVIDUAL PRODUK

const ProductsCategory = () => {
	const { merek, categoryIndex } = useParams();

	let categoryName;
	let singleProducts;

	for (let i = 0; i < products.length; i++) {
		if (products[i].merek.toLowerCase() === merek) {
			categoryName =
				products[i].groupedProducts[categoryIndex].groupedProductTitle;
			singleProducts = products[i].groupedProducts[categoryIndex].products;
		}
	}

	return (
		<div className="flex flex-col gap-2 lg:container mx-auto px-4">
			<div className="flex flex-col gap-4 my-8">
				<div className="flex flex-row gap-4 justify-start items-center">
					<button
						onClick={() => {
							window.history.back();
						}}
						className="w-12 aspect-square bg-[#F16213] p-2"
					>
						<img
							src={LeftArrowIcon}
							alt=""
							className="w-full h-auto filter invert"
						/>
					</button>

					<div className="mb-[-5px]">
						<p className="text-5xl font-bold uppercase leading-none">
							{categoryName}
						</p>
					</div>
				</div>

				<div className="grid grid-cols-4 gap-4">
					{singleProducts.map((singleProduct, index) => {
						return (
							<a
								href={singleProduct.productPdf}
								target="_blank"
								rel="noreferrer"
							>
								<div className="relative w-full flex flex-col h-full">
									<img
										src={singleProduct.productThumbnail}
										alt=""
										className="w-full border-[#404040] border-2 h-auto"
									/>

									<div className=" bg-[#404040] text-white px-3 py-3 flex flex-col items-center justify-center h-full">
										<p className="text-xl font-bold text-center">
											{singleProduct.productName}
										</p>
										{/* <p className="font-light text-center">
													{product.productSubTitle}
												</p> */}
									</div>
								</div>
							</a>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ProductsCategory;
