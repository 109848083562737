// BANNER BUILDEX
import imgBannerBuildex from './productBanner/buildexBanner.png';

// CATEGORY
import imgCategoryHighQualityScrew from './indvProductImg/buildex/category/BUILDEX (High Quality Screw).png';

// Thumbnail & PDF
import thumbBuildexScrew from '../products/productsThumbPdf/buildex/BUILDEX (High Quality Screw).png';
import pdfBuildexScrew from '../products/productsThumbPdf/buildex/ITEWE Buildex.pdf';

const buildex = {
	merek: 'buildex',
	bannerImg: imgBannerBuildex,
	bannerPromo: 'Produk Favorit',
	bannerTitle: 'BUILDEX',
	bannerDesc:
		'Pengencang sekrup yang direkayasa canggih di setiap hasil produksi unit tunggal untuk kepuasan tertinggi pelanggan dan kinerja hasil akhir di lokasi',
	bannerLink: '',
	groupedProducts: [
		{
			groupedProductImg: imgCategoryHighQualityScrew,
			groupedProductTitle: 'High Quality Industrial Screw',
			groupedProductDesc:
				'Advanced engineered screw fasteners with highest test standard & International approvals',
			products: [
				{
					productName: 'High Quality Industrial Screw',
					productThumbnail: thumbBuildexScrew,
					productPdf: pdfBuildexScrew,
				},
			],
		},
	],
};

export default buildex;
