// BANNER DEWALT
import imgBannerDewalt from './productBanner/dewaltBanner.png';

// CATEGORY
import imgCategoryCordlessNailer from './indvProductImg/dewalt/category/DEWALT (Cordless Concrete Nailer).png';

// Thumbnail & PDF
import thumbDewaltCordlessConcreteNailer from './../products/productsThumbPdf/dewalt/DEWALT (Cordless Concrete Nailer).png';
import pdfDewaltCordlessConcreteNailer from './../products/productsThumbPdf/dewalt/ITEWE Dewalt.pdf';

const dewalt = {
	merek: 'dewalt',
	bannerImg: imgBannerDewalt,
	bannerPromo: 'Produk Favorit',
	bannerTitle: 'DCN890',
	bannerDesc:
		'Paku tembak dengan pengaturan kecepatan tembak yang dapat disesuaikan dengan kebutuhan dalam tanam paku dan kekerasan bahan dasare tanpa mesiu dan gas',
	bannerLink: '',
	groupedProducts: [
		{
			groupedProductImg: imgCategoryCordlessNailer,
			groupedProductTitle: 'Cordless Concrete Nailer',
			groupedProductDesc:
				'Built for safe & speedy concrete and steel nailer applications that maximize project productivity with variable power setting, tool-free jam clearing and a high capacity nails magazine system, ensuring maximum tool versatility and conveniences',
			products: [
				{
					productName: 'Cordless Concrete Nailer',
					productThumbnail: thumbDewaltCordlessConcreteNailer,
					productPdf: pdfDewaltCordlessConcreteNailer,
				},
			],
		},
	],
};

export default dewalt;
