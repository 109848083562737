import React from 'react';

import iteweLogo from '../assets/Itewe_Logo.png';

const Navbar = () => {
	return (
		<nav className="bg-[#F16213]">
			<div className="px-4 lg:container mx-auto flex flex-row justify-between items-center  h-20">
				<div>
					<a href="/">
						<img src={iteweLogo} alt="" className="h-10" />
					</a>
				</div>

				<div className="flex flex-row gap-6 text-xl text-white font-normal tracking-tight">
					<a
						href="/about"
						className="border-transparent border-b-2 hover:border-white transition-all"
					>
						About Us
					</a>
					<a
						href="/products"
						className="border-transparent border-b-2 hover:border-white transition-all"
					>
						Products
					</a>
					<a
						href="/certifications"
						className="border-transparent border-b-2 hover:border-white transition-all"
					>
						Certifications
					</a>
					<a
						href="/projects"
						className="border-transparent border-b-2 hover:border-white transition-all"
					>
						Projects
					</a>
					<a
						href="/events"
						className="border-transparent border-b-2 hover:border-white transition-all"
					>
						Events
					</a>
					<a
						href="/downloads"
						className="border-transparent border-b-2 hover:border-white transition-all"
					>
						Downloads
					</a>
					<a
						href="/contactus"
						className="border-transparent border-b-2 hover:border-white transition-all"
					>
						Contact Us
					</a>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
