// BANNER GNET
import gnetBanner from '../products/productBanner/gnetBanner.png';

// CATEGORY
import imgCategoryMineralInsulations from './indvProductImg/gnet/category/GNET (Mineral Stone Wool Insulations).png';
import imgCategoryBuildingMaterialDist from './indvProductImg/gnet/category/GNET (Building Materials Distributions).png';

// Thumbnail & PDF
import thumbGnetMineralInsulation from '../products/productsThumbPdf/gnet/GNET (Mineral Stone Wool Insulations)/GNET (Mineral Stone Wool Insulations).png';
import pdfGnetMineralInsulation from '../products/productsThumbPdf/gnet/GNET (Mineral Stone Wool Insulations)/GNET (Mineral Stone Wool Insulations).pdf';

const gnet = {
	merek: 'gnet',
	bannerImg: gnetBanner,
	bannerPromo: 'Produk Favorit',
	bannerTitle: 'GNET',
	bannerDesc:
		'GNET merupakan merek dagang produk bahan bangunan berkualitas dari Global Group yang diluncurkan tahun 2016 lalu. Saat ini GNET menyediakan 4 kategori produk, yakni GNET Roofing, GNET Lining, GNET Furnish, dan GNET Flooring',
	bannerLink: '',
	groupedProducts: [
		{
			groupedProductImg: imgCategoryMineralInsulations,
			groupedProductTitle: 'Mineral Stone Wool Insulations',
			groupedProductDesc:
				'Best trusted recognized brand for all in one most effective Fire-safe, Thermal & Acoustic insulations with wide range of density & dimensions selections in accordance with project specifics designs and requirements',
			products: [
				{
					productName: 'Mineral Stone Wool Insulations',
					productThumbnail: thumbGnetMineralInsulation,
					productPdf: pdfGnetMineralInsulation,
				},
			],
		},
		{
			groupedProductImg: imgCategoryBuildingMaterialDist,
			groupedProductTitle: 'Building Materials Distributions',
			groupedProductDesc:
				'Complete one stop supply for most applicable building materials in variances specification and price ranges in one GNET trusted brand with more than 44 nation spreadwide distribution channels & manufacturing sites',
			products: [],
		},
	],
};

export default gnet;
