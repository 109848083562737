import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './scenes/home';
import About from './scenes/about';
import Products from './scenes/products';
import GroupedProducts from './scenes/productsGroup';
import ProductsCategory from './scenes/productsCategory';
import Certifications from './scenes/certifications';
import ContactUs from './scenes/contactus';
import Projects from './scenes/projects';
import Events from './scenes/events';
import Downloads from './scenes/downloads';
import Olshop from './scenes/olshop';
// import ProductIndividual from './scenes/productSingle';

function App() {
	return (
		<div className="App">
			<Navbar />

			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />
					<Route path="/certifications" element={<Certifications />} />
					<Route path="/projects" element={<Projects />} />
					<Route path="/events" element={<Events />} />
					<Route path="/downloads" element={<Downloads />} />
					<Route path="/contactus" element={<ContactUs />} />
					<Route path="/olshop" element={<Olshop />} />

					<Route path="/products">
						<Route index element={<Products />} />

						<Route path=":merek">
							<Route index element={<GroupedProducts />} />

							<Route path=":categoryIndex">
								<Route index element={<ProductsCategory />} />

								{/* <Route path=":paketIndex/:productIndex">
									<Route index element={<ProductIndividual />} />
								</Route> */}
							</Route>
						</Route>
					</Route>

					{/* 404 NOT FOUND */}
					<Route path="*" element={<Home />} />
				</Routes>
			</BrowserRouter>

			<Footer />
		</div>
	);
}

export default App;
