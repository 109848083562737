import React from 'react';
import { useParams } from 'react-router';
import products from '../../models/products.js';

// HALAMAN MEREK PRODUK
// ADA GROUPING PER PRODUK

const GroupedProducts = () => {
	const { merek } = useParams();

	let bannerImg;
	let bannerPromo;
	let bannerTitle;
	let bannerDesc;
	let bannerLink;
	let groupedProducts;

	for (let i = 0; i < products.length; i++) {
		if (products[i].merek.toLowerCase() === merek) {
			bannerImg = products[i].bannerImg;
			bannerPromo = products[i].bannerPromo;
			bannerTitle = products[i].bannerTitle;
			bannerDesc = products[i].bannerDesc;
			bannerLink = products[i].bannerLink;
			groupedProducts = products[i].groupedProducts;
		}
	}

	return (
		<div className="lg:container mx-auto px-4 my-12">
			<div className="flex flex-col gap-2 p-8 border-[#404040] border-2 mb-24 relative">
				<span className="absolute -top-7 bg-[#EF6323] px-4 py-2 uppercase text-white text-3xl">
					{bannerPromo}
				</span>

				<img src={bannerImg} alt="" className="full h-auto" />

				{bannerDesc ? <span className="text-4xl">{bannerTitle}</span> : null}
				{bannerDesc ? <span className="font-light ">{bannerDesc}</span> : null}

				{bannerLink ? (
					<a
						href={bannerLink}
						className="bg-[#F16213] text-white w-fit px-4 py-2 rounded-full"
					>
						Lihat Selengkapnya
					</a>
				) : null}
			</div>

			<div className="flex flex-col gap-8">
				<span className="text-5xl text-[#EF6323] font-bold">
					Apa yang anda butuhkan?
				</span>

				<div className="grid grid-cols-4 gap-4">
					{groupedProducts.map((groupedProduct, index) => {
						return (
							<>
								{/* GAADA PDF */}
								{groupedProduct.products.length === 0 ? (
									<div className="relative w-full h-full flex flex-col group overflow-hidden">
										<img
											src={groupedProduct.groupedProductImg}
											alt=""
											className="w-full h-auto border-[#404040] border-2"
										/>

										<div className=" bg-[#404040] text-white px-3 py-3 h-full">
											<span className="text-3xl font-bold">
												{groupedProduct.groupedProductTitle}
											</span>
										</div>

										<div className="absolute top-0 left-0 w-full h-full flex justify-center items-center px-4 transition-transform transform translate-x-full group-hover:translate-x-0">
											<p className=" text-white text-md xl:text-lg z-10 ">
												{groupedProduct.groupedProductDesc}
											</p>

											<div className="bg-[#404040] absolute top-0 left-0 w-full h-full opacity-80"></div>
										</div>
									</div>
								) : null}

								{/* 1 PDF */}
								{groupedProduct.products.length === 1 ? (
									<a
										href={groupedProduct.products[0].productPdf}
										target="_blank"
										rel="noreferrer"
									>
										<div className="relative w-full h-full flex flex-col group overflow-hidden">
											<img
												src={groupedProduct.groupedProductImg}
												alt=""
												className="w-full h-auto border-[#404040] border-2"
											/>

											<div className=" bg-[#404040] text-white px-3 py-3 h-full">
												<span className="text-3xl font-bold">
													{groupedProduct.groupedProductTitle}
												</span>
											</div>

											<div className="absolute top-0 left-0 w-full h-full flex justify-center items-center px-4 transition-transform transform translate-x-full group-hover:translate-x-0">
												<p className=" text-white text-md xl:text-lg z-10 ">
													{groupedProduct.groupedProductDesc}
												</p>

												<div className="bg-[#404040] absolute top-0 left-0 w-full h-full opacity-80"></div>
											</div>
										</div>
									</a>
								) : null}

								{/* Lebih dari 2 PDF */}
								{groupedProduct.products.length > 1 ? (
									<a href={`/products/${merek}/${index}`}>
										<div className="relative w-full h-full flex flex-col group overflow-hidden">
											<img
												src={groupedProduct.groupedProductImg}
												alt=""
												className="w-full h-auto border-[#404040] border-2"
											/>

											<div className=" bg-[#404040] text-white px-3 py-3 h-full">
												<span className="text-3xl font-bold">
													{groupedProduct.groupedProductTitle}
												</span>
											</div>

											<div className="absolute top-0 left-0 w-full h-full flex justify-center items-center px-4 transition-transform transform translate-x-full group-hover:translate-x-0">
												<p className=" text-white text-md xl:text-lg z-10 ">
													{groupedProduct.groupedProductDesc}
												</p>

												<div className="bg-[#404040] absolute top-0 left-0 w-full h-full opacity-80"></div>
											</div>
										</div>
									</a>
								) : null}
							</>
						);
					})}
				</div>
			</div>
		</div>

		// <div className="flex flex-col gap-2">
		// 	<span>Merek - {bannerTitle}</span>
		// 	<img src={bannerImg} alt="" className="w-64" />
		// 	<span>{bannerPromo}</span>
		// 	<span>{bannerTitle}</span>
		// 	<span>{bannerDesc}</span>
		// 	<a href={bannerLink}>link</a>

		// 	{groupedProducts.map((groupedProduct, index) => {
		// 		return (
		// 			<div className="flex flex-col gap-2">
		// 				<img
		// 					src={groupedProduct.groupedProductImg}
		// 					alt=""
		// 					className="w-64"
		// 				/>
		// 				<span>{groupedProduct.groupedProductTitle}</span>
		// 				<span>{groupedProduct.groupedProductDesc}</span>
		// 				<a href={`/products/${merek}/${index}`}>link</a>
		// 			</div>
		// 		);
		// 	})}
		// </div>
	);
};

export default GroupedProducts;
