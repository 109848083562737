// BANNER ROCKWOOL
import imgBannerRockwool from './productBanner/rockwoolBanner.png';

// CATEGORY
import imgCategoryInsulation from './indvProductImg/rockwool/category/ROCKWOOL (Fire Acoustic Insulation).png';

// Thumbnail & PDF
import thumbRockwoolFireInsulation from './../products/productsThumbPdf/rockwool/ROCKWOOL (Fire Acoustic Insulation).png';
import pdfRockwoolFireInsulation from './../products/productsThumbPdf/rockwool/ITEWE Rockwool.pdf';

const rockwool = {
	merek: 'rockwool',
	bannerImg: imgBannerRockwool,
	bannerPromo: 'Produk Favorit',
	bannerTitle: 'Fire & Acoustic Insulation',
	bannerDesc:
		'Dengan serat yang berbentuk busa terbuat dari lelehan batu gunung berapi layaknya seperti batu basalt yang dapat menahan air dengan baik',
	bannerLink: '',
	groupedProducts: [
		{
			groupedProductImg: imgCategoryInsulation,
			groupedProductTitle: 'Fire & Acoustic Insulation',
			groupedProductDesc:
				'Best trusted brand solutions for all in one Fire safe, thermal & acoustic insulations with wide ranges of application based product selections with comprehensive lab test reports & international approvals and unbeaten technical supports',
			products: [
				{
					productName: 'Fire & Acoustic Insulation',
					productThumbnail: thumbRockwoolFireInsulation,
					productPdf: pdfRockwoolFireInsulation,
				},
			],
		},
	],
};

export default rockwool;
