import React from 'react';

import iconBukalapak from './../assets/icon/bukalapak.png';
import iconTokopedia from './../assets/icon/tokopedia.png';
import iconShopee from './../assets/icon/shopee.png';

import iconFacebook from './../assets/icon/facebook.png';
import iconLinkedin from './../assets/icon/linkedin.png';
import iconInstagram from './../assets/icon/instagram.png';
import iconYoutube from './../assets/icon/youtube.png';

import iteweLogo from '../assets/Itewe_Logo.png';

const Footer = () => {
	return (
		<div className="bg-[#F16213]">
			<div className="px-4 lg:container flex flex-row justify-between items-center mx-auto py-6">
				<div className="text-white flex flex-col gap-1">
					<a href="/">
						<img src={iteweLogo} alt="" className="h-10 mb-2 mt-4" />
					</a>

					<span className="text-3xl">PT. ITEWE SARANA KONSTRUKSI</span>

					<div className="flex flex-col">
						<span>SpringHill Office Tower Lt.5, Unit A-H. </span>
						<span>Jl. Benyamin Sueb Ruas D7 Blok D6,</span>
						<span>Kec. Pademangan. Jakarta Utara 14410,</span>
					</div>

					<div className="flex flex-row gap-6 mt-4">
						<a
							href="https://www.instagram.com/itewe_isk"
							target="_blank"
							rel="noreferrer"
						>
							<img src={iconInstagram} alt="" className="h-10" />
						</a>

						<a
							href="https://www.facebook.com/iteweramset"
							target="_blank"
							rel="noreferrer"
						>
							<img src={iconFacebook} alt="" className="h-10" />
						</a>

						<a
							href="https://www.linkedin.com/company/itewe-sarana-konstruksi/"
							target="_blank"
							rel="noreferrer"
						>
							<img src={iconLinkedin} alt="" className="h-10" />
						</a>

						<a
							href="https://www.youtube.com/@iteweindonesia9846"
							target="_blank"
							rel="noreferrer"
						>
							<img src={iconYoutube} alt="" className="h-10" />
						</a>
					</div>
				</div>

				<div className="flex flex-col gap-2">
					<span className="text-center text-2xl text-white">
						Our Official Store
					</span>

					<div className="flex flex-row gap-6 justify-center ">
						<a
							href="https://www.bukalapak.com/u/pt_itewe_sarana_konstruksi"
							target="_blank"
							rel="noreferrer"
						>
							<img src={iconBukalapak} alt="" className="h-10" />
						</a>

						<a
							href="https://www.tokopedia.com/itewe"
							target="_blank"
							rel="noreferrer"
						>
							<img src={iconTokopedia} alt="" className="w-10" />
						</a>

						<a
							href="https://shopee.co.id/itewe_sarana_konstruksi"
							target="_blank"
							rel="noreferrer"
						>
							<img src={iconShopee} alt="" className="w-10" />
						</a>
					</div>
				</div>
			</div>

			<div className="flex flex-row justify-center align-middle bg-[#AF3E00] py-2">
				<span className="text-white font-normal opacity-75">
					Copyright &copy; 2023 PT. ITEWE Sarana Konstruksi
				</span>
			</div>
		</div>
	);
};

export default Footer;
